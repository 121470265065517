



























import { Component, Prop, Provide, ProvideReactive, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import StudentOverview from "@/views/Student/Overview/StudentOverview.vue";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import { mixins } from "vue-class-component";
import StudentEducationRequestMixin from "@/mixins/Request/StudentEducationRequestMixin";
import AbibaroRequestMixin from "@/mixins/Request/AbibaroRequestMixin";
import SaldoRequestMixin from "@/mixins/Request/SaldoRequestMixin";
import PaymentRequestMixin from "@/mixins/Request/PaymentRequestMixin";
import { IPayment } from "@/interfaces/Student/IPayment";
import { IPaymentForm } from "@/interfaces/Student/IPaymentForm";
import AppointmentRequestMixin from "@/mixins/Request/AppointmentRequestMixin";
import StudentEducation from "@/views/Student/Overview/StudentEducation.vue";
import { IExamAndEducation } from "@/interfaces/Exam/IExamAndEducation";
import AppointmentsCountRequestMixin from "@/mixins/Request/AppointmentsCountRequestMixin";

@Component({
  components: { StudentEducation, StudentOverview, AbortButton },
})
export default class StudentDetails extends mixins(
  StudentRequestMixin,
  StudentEducationRequestMixin,
  AbibaroRequestMixin,
  SaldoRequestMixin,
  PaymentRequestMixin,
  AppointmentRequestMixin,
  AppointmentsCountRequestMixin
) {
  public name = "StudentDetails";

  @Prop({ required: true })
  public selectedStudentId!: number | null;

  @Prop({ type: Number })
  public balanceActive!: number;

  @Prop({ type: Number })
  public balanceBottom!: number;

  @Prop({ type: Function, default: () => null })
  public closeStudentInfo!: () => void;

  @Prop({ type: Function, default: () => null })
  public resetBalance!: () => void;

  @Prop({ type: Function, default: () => null })
  public resetBottomTab!: (index: number) => void;

  @Prop({ type: Function, default: () => null })
  public resetStudents!: () => Promise<void>;

  @Prop({ type: Function, default: () => null })
  public changeActiveLearnStatusTab!: () => void;

  @Prop({ type: Boolean, default: () => false })
  public showCloseBtn!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public disabled!: boolean;

  @Prop({ type: Array, default: () => [], required: true })
  public studentEducationIds!: number[];

  @ProvideReactive("isDisabled")
  public get isDisabled(): boolean {
    return this.disabled;
  }

  @ProvideReactive("topTab")
  public get topTab() {
    return this.balanceActive;
  }

  @ProvideReactive("updateTopTab")
  public updateTopTab(index: number) {
    this.$emit("update:balanceActive", index);
  }

  @ProvideReactive("bottomTab")
  public get bottomTab() {
    return this.balanceBottom;
  }

  @ProvideReactive("updateBottomTab")
  public updateBottomTab(index: number) {
    this.$emit("update:balanceBottom", index);
  }

  @Provide("onArchiveStudent")
  private async onArchiveStudent(option: { id: number; archived: boolean }): Promise<void> {
    await this.archiveStudent(option.id, option.archived);
  }

  private async checkStudentAbibaroRegistration(studentId: number) {
    await this.checkAbibaroRegistration();

    if (this.registeredInAbibaro) {
      this.getStudentEducationsAbibaroStatus(studentId);
    }
  }

  private get allSaldo() {
    if (!this.saldo || !Array.isArray(this.saldo) || this.saldo.length === 0) {
      return [];
    }

    return this.saldo;
  }

  @Watch("selectedStudentId", { immediate: true })
  private onChangeSelectedStudentId(studentId: number) {
    if (studentId) {
      this.fetchStudentOverviewData(studentId);
    }
  }

  private fetchStudentOverviewData(studentId: number) {
    this.fetchStudent(studentId);
    this.fetchStudentEducationsArchived(studentId, true);
    this.checkStudentAbibaroRegistration(studentId);
    this.findSaldoByStudent(studentId);
    // this.findAppointmentsByStudent(studentId);
    this.findAppointmentsCountByStudent(studentId);
    this.findExamAndEducationByStudent(studentId, true);
  }

  public async onSubmitBalanceTop(payment: IPaymentForm): Promise<void> {
    const paymentItem: IPayment = {
      description: payment.description,
      refund: payment.refund,
      date: payment.date,
      amountInEur: payment.amountInEur,
      studentEducationId: payment.studentEducation.id,
      paymentMethod: payment.paymentMethod,
      vat: payment.vat,
    };

    await this.createPayment(paymentItem);

    if (this.createPaymentSuccess && this.selectedStudentId) {
      this.findSaldoByStudent(this.selectedStudentId);
      this.resetStudents();
    }
  }

  private get studentCardLoading() {
    return this.studentLoading || this.studentEducationsLoading || this.studentEducationArchiveLoading;
  }

  private get studentDriveBuzzAbibaroLoading() {
    return this.studentLoading || this.studentEducationsLoading || this.checkAbibaroRegistrationLoading || this.studentEducationArchiveLoading;
  }

  private get studentSaldoLoading() {
    return this.saldoLoading;
  }

  private get studentEducationLoadingProxy() {
    return this.studentLoading || this.studentEducationsLoading || this.examAndEducationByStudentLoading || this.studentEducationArchiveLoading;
  }

  private get appointmentsLoadingProxy() {
    return this.studentEducationsLoading || this.appointmentsByStudentLoading || this.studentEducationArchiveLoading;
  }

  private get studentChecksLoading() {
    return this.studentLoading || this.studentEducationsLoading || this.studentEducationArchiveLoading;
  }
  private get mandateLoading() {
    return this.studentLoading || this.studentEducationsLoading || this.studentEducationArchiveLoading;
  }

  private get studentArchiveLoading() {
    return this.studentLoading;
  }

  private async onStudentEducationArchive(selectedEducation: IExamAndEducation): Promise<void> {
    const studentEducationId = selectedEducation?.studentEducationId;
    if (studentEducationId) {
      await this.studentEducationArchive(studentEducationId);

      if (this.studentEducationArchiveSuccess && this.selectedStudentId) {
        this.fetchStudentOverviewData(this.selectedStudentId);
      }
    }
  }

  private async onStudentStatusChanged(studentId: number): Promise<void> {
    if (studentId) {
      this.fetchStudentOverviewData(studentId);
    }
  }

  public mounted() {
    this.$root.$on("refresh-student-info", () => {
      if (this.selectedStudentId) {
        this.fetchStudentOverviewData(this.selectedStudentId);
      }
    });
  }
}
