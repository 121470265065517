var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.typeSignature === 'Ausbildungsvertag')?_c('b-checkbox',{model:{value:(_vm.termsAndConditions),callback:function ($$v) {_vm.termsAndConditions=$$v},expression:"termsAndConditions"}},[_vm._v(" Hiermit bestätigst Du, dass Du unsere AGB und den Preisaushang mit den Unterrichtsentgelten der Fahrschule zur Kenntnis genommen hast und akzeptierst. Die Gebühren für Behörden und die Prüforganisation werden gesondert berechnet. (Gerne händigen wir Dir auf Wunsch unsere AGB auch in der Fahrschule aus.) ")]):_vm._e(),(_vm.typeSignature === 'Ausbildung bestätigt')?_c('span',{staticClass:"font-size-18"},[_vm._v(" Hiermit bestätige ich die vorab von mir kontrollierten Theorie- bzw. Fahrstunden! ")]):_vm._e(),(_vm.typeSignature === 'Ausbildungsvertag')?_c('b-checkbox',{model:{value:(_vm.confirmChargeableAddons),callback:function ($$v) {_vm.confirmChargeableAddons=$$v},expression:"confirmChargeableAddons"}},[_vm._v(" "+_vm._s(_vm.addonsCheckboxText)+" ")]):_vm._e(),(_vm.typeSignature === 'Ausbildungsvertag')?_c('b-checkbox',{model:{value:(_vm.confirmGDPRs),callback:function ($$v) {_vm.confirmGDPRs=$$v},expression:"confirmGDPRs"}},[_vm._v(" "+_vm._s(_vm.gdprUrlCheckboxText)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('VueSignaturePad',{ref:"studentSignature",staticClass:"signature-style bg-light mt-10",attrs:{"id":"student-signature","width":_vm.padW,"height":_vm.padH,"options":{
          minWidth: 3,
          maxWidth: 6,
          onBegin: function () {
            _vm.$refs.studentSignature.resizeCanvas();
          },
        }}}),_c('span',[_vm._v(_vm._s(_vm.$t("general.signature_student")))])],1),(!_vm.isAdult && _vm.typeSignature === 'Ausbildungsvertag')?_c('div',{staticClass:"d-flex flex-column align-items-center"},[(!_vm.isAdult && _vm.typeSignature === 'Ausbildungsvertag')?_c('VueSignaturePad',{ref:"parentSignature",staticClass:"signature-style bg-light mt-10",attrs:{"id":"parent-signature","width":_vm.padW,"height":_vm.padH,"options":{
          minWidth: 3,
          maxWidth: 6,
          onBegin: function () {
            _vm.$refs.parentSignature.resizeCanvas();
          },
        }}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("general.signature_parent")))])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }