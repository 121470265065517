




import { Component, Vue } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class FileCsvButton extends Vue {
  public name = "FileCsvButton";
}
