





















import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import { IRegistrationInfo } from "@/interfaces/IRegistrationInfo";
import Datepicker from "@/components/Datepicker.vue";

@Component({
  components: { Datepicker, FscModal },
})
export default class RegistrationInfoFormModal extends Vue {
  public name = "RegistrationInfoFormModal";

  @Prop({ type: String, required: true })
  protected modalId!: string;

  @Prop({ required: true })
  protected registrationInfo!: IRegistrationInfo;
}
