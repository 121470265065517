import { Route } from "vue-router";

/**
 * Constructs tab/view label/title
 * out of the route and
 * custom label if set
 * @param $route
 * @param customLabel
 */
export function getViewLabel($route: Route, customLabel?: string | null): string {
  let label = customLabel ? customLabel : null;
  if (!label) {
    label = $route.meta && $route.meta.title ? $route.meta.title : null;
  }
  if (!label) {
    label = $route.name ? $route.name : "";
  }
  return label;
}

export const ulrStringify = (params: Record<any, any> | undefined): string => {
  if (params) {
    return `?${new URLSearchParams(params).toString()}`;
  }
  return "";
};
