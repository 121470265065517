import { Component, Vue } from "vue-property-decorator";

@Component
export default class SaldoMixin extends Vue {
  public formatSaldo(item: any): string {
    let formattedSaldo: any = 0;
    if (item.balance && item.balance.saldo) {
      const saldoAll = item.balance.saldo.toString();
      const containsNegative = saldoAll.includes("-");
      if (containsNegative) {
        const negativeRemoved = saldoAll.slice(1);
        const positiveSaldo = negativeRemoved.split(".");
        const finalFormatSaldo = this.formattingSaldo(positiveSaldo);
        formattedSaldo = `- ${finalFormatSaldo}`;
      } else {
        const splittedSaldo = saldoAll.split(".");
        formattedSaldo = this.formattingSaldo(splittedSaldo);
      }
    }
    return `${formattedSaldo} €`;
  }

  public formattingSaldo(splittedSaldo: any) {
    let formattedSaldo: any = 0;
    if (splittedSaldo.length > 1) {
      if (splittedSaldo[0].length > 3) {
        const point = ".";
        const position = splittedSaldo[0].length - 3;
        const positionedSaldo = [splittedSaldo[0].slice(0, position), point, splittedSaldo[0].slice(position)].join("");
        formattedSaldo = `${positionedSaldo}, ${splittedSaldo[1]}`;
      } else {
        formattedSaldo = `${splittedSaldo[0]},${splittedSaldo[1]}`;
      }
    } else {
      if (splittedSaldo[0].length > 3) {
        const point = ".";
        const position = splittedSaldo[0].length - 3;
        const positionedSaldo = [splittedSaldo[0].slice(0, position), point, splittedSaldo[0].slice(position)].join("");
        formattedSaldo = `${positionedSaldo}`;
      } else {
        formattedSaldo = `${splittedSaldo[0]}`;
      }
    }
    return formattedSaldo;
  }

  public formatSaldoBalance(item: any): string {
    let formattedSaldo: any = 0;
    if (item) {
      const saldoAll = item.toString();
      const containsNegative = saldoAll.includes("-");
      if (containsNegative) {
        const negativeRemoved = saldoAll.slice(1);
        const positiveSaldo = negativeRemoved.split(".");
        const finalFormatSaldo = this.formattingSaldo(positiveSaldo);
        formattedSaldo = `- ${finalFormatSaldo}`;
      } else {
        const splittedSaldo = saldoAll.split(".");
        formattedSaldo = this.formattingSaldo(splittedSaldo);
      }
    }
    return `${formattedSaldo} €`;
  }
}
