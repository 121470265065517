import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import { ulrStringify } from "@/utils/UrlUtils";
import createCancelableRequest from "@/utils/axiosCancelable";
import { IChecklistTemplate } from "@/interfaces/Student/IChecklistTemplate";

const URL = "checklist-templates";

@Component
export default class CheckListTemplateRequestMixin extends Vue {
  private checklistTemplateCancelableRequest = createCancelableRequest(CheckListTemplateRequestMixin.name);

  public checkListTemplates: Array<IChecklistTemplate> = [];
  private checkListTemplatesLoadingQueue = 0;
  public checkListTemplatesSuccess = false;
  public checkListTemplatesError: Record<any, any> | null = null;

  public get checkListTemplatesLoading() {
    return this.checkListTemplatesLoadingQueue > 0;
  }

  public async findAllCheckListTemplates(params: any = null): Promise<void> {
    this.checkListTemplatesLoadingQueue++;
    this.checkListTemplatesSuccess = false;
    this.checkListTemplatesError = null;

    const args = ulrStringify(params);

    return await this.checklistTemplateCancelableRequest
      .makeRequest({ url: `${URL}${args}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.checkListTemplates = response.data;
        this.checkListTemplatesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.checkListTemplatesError = error.response?.data;
      })
      .finally(() => {
        this.checkListTemplatesLoadingQueue--;
      });
  }

  public destroyed() {
    this.checklistTemplateCancelableRequest.cancelPreviousRequest();
  }
}
