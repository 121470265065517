import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IRegistrationInfo } from "@/interfaces/IRegistrationInfo";
import createCancelableRequest from "@/utils/axiosCancelable";

const URL = "registration-info";

@Component
export default class RegistrationInfoRequestMixin extends Vue {
  private registrationInfoCancelableRequest = createCancelableRequest(RegistrationInfoRequestMixin.name);

  public registrationInfoStudentEducation: IRegistrationInfo | null = {} as IRegistrationInfo;
  private registrationInfoStudentEducationLoadingQueue = 0;
  public registrationInfoStudentEducationSuccess = false;
  public registrationInfoStudentEducationError: Record<any, any> | null = null;

  public get registrationInfoStudentEducationLoading() {
    return this.registrationInfoStudentEducationLoadingQueue > 0;
  }

  public async findRegistrationInfoByStudentEducation(studentEducationId: number): Promise<void> {
    this.registrationInfoStudentEducationLoadingQueue++;
    this.registrationInfoStudentEducationSuccess = false;
    this.registrationInfoStudentEducationError = null;

    return await this.registrationInfoCancelableRequest
      .makeRequest({ url: `${URL}/by-student-education/${studentEducationId}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.registrationInfoStudentEducation = response.data;
        this.registrationInfoStudentEducationSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.registrationInfoStudentEducationError = error.response?.data;
      })
      .finally(() => {
        this.registrationInfoStudentEducationLoadingQueue--;
      });
  }

  public updatedRegistrationInfo: Record<any, any> | null = null;
  public updateRegistrationInfoLoading = false;
  public updateRegistrationInfoSuccess = false;
  public updateRegistrationInfoError: Record<any, any> | null = null;

  public async updateRegistrationInfo(registrationInfo: any): Promise<void> {
    this.updateRegistrationInfoLoading = true;
    this.updateRegistrationInfoSuccess = false;
    this.updateRegistrationInfoError = null;
    return await axios
      .post(`${URL}`, registrationInfo)
      .then((response: AxiosResponse) => {
        this.updatedRegistrationInfo = response.data;
        this.updateRegistrationInfoSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updateRegistrationInfoError = error.response?.data;
      })
      .finally(() => {
        this.updateRegistrationInfoLoading = false;
      });
  }

  public destroyed() {
    this.registrationInfoCancelableRequest.cancelPreviousRequest();
  }
}
