export const PROOF_OF_TRAINING = "PROOF_OF_TRAINING";
export const B96_CERTIFICATE = "B96_CERTIFICATE";
export const B197_CERTIFICATE = "B197_CERTIFICATE";
export const ASF_CERTIFICATE = "ASF_CERTIFICATE";
export const B196_CERTIFICATE = "B196_CERTIFICATE";
export const MOFA_CERTIFICATE = "MOFA_CERTIFICATE";
export const FES_CERTIFICATE = "FES_CERTIFICATE";
export const BKF_CERTIFICATE = "BKF_CERTIFICATE";
export const BKF_BASIC_CERTIFICATE = "BKF_BASIC_CERTIFICATE";
export const EDUCATION_CONTRACT = "EDUCATION_CONTRACT";

export const CERTIFICATE_TYPES = [
  {
    id: 202,
    type: EDUCATION_CONTRACT,
    value: "training-contract",
    text: "Ausbildungsvertrag",
  },
  {
    id: 500,
    type: PROOF_OF_TRAINING,
    value: "proof-of-training",
    text: "Ausbildungsnachweis",
  },
  {
    id: 502,
    type: B96_CERTIFICATE,
    value: "b96-certificate",
    text: "B96 Zertifikat",
  },
  {
    id: 503,
    type: B197_CERTIFICATE,
    value: "b197-certificate",
    text: "B197 Zertifikat",
  },
  {
    id: 504,
    type: ASF_CERTIFICATE,
    value: "asf-certificate",
    text: "ASF Zertifikat",
  },
  {
    id: 505,
    type: B196_CERTIFICATE,
    value: "b196-certificate",
    text: "B196 Zertifikat",
  },
  {
    id: 506,
    type: MOFA_CERTIFICATE,
    value: "mofa-certificate",
    text: "MOFA Zertifikat",
  },
  {
    id: 507,
    type: FES_CERTIFICATE,
    value: "fes-certificate",
    text: "FES Zertifikat",
  },
  {
    id: 508,
    type: BKF_CERTIFICATE,
    value: "bkf-certificate",
    text: "Weiterbildung BKF",
  },
  {
    id: 509,
    type: BKF_BASIC_CERTIFICATE,
    value: "bkf-basic-certificate",
    text: "Grundqualifikation BKF",
  },
];
