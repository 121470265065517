




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DrivingLessonsEducationRow extends Vue {
  public name = "DrivingLessonsEducationRow";

  @Prop()
  public lessonName!: any;

  @Prop()
  public completedLessons!: any;

  @Prop()
  public completedLessonsMinutes!: any;

  @Prop()
  public externalServices!: any;

  @Prop()
  public externalServicesMinutes!: any;

  @Prop()
  public requiredLessons!: any;

  @Prop()
  public requiredLessonsMinutes!: any;

  @Prop()
  public plannedLessons!: any;

  @Prop()
  public plannedLessonsMinutes!: any;

  @Prop()
  public toolTip!: any;

  public get formattedCompletedLessons() {
    if (this.completedLessons && this.completedLessons > 9) {
      return `${this.completedLessons}`;
    } else if (this.completedLessons < 10 && this.completedLessons > 0) {
      return `0${this.completedLessons}`;
    }
    return 0;
  }

  public get formattedCompletedLessonsMinutes() {
    if (this.completedLessonsMinutes && this.completedLessonsMinutes > 9) {
      return `${this.completedLessonsMinutes}`;
    } else if (this.completedLessonsMinutes < 10 && this.completedLessonsMinutes > 0) {
      return `0${this.completedLessonsMinutes}`;
    }
    return "00";
  }

  public get formattedPlannedLessons() {
    if (this.plannedLessons && this.plannedLessons > 9) {
      return `${this.plannedLessons}`;
    } else if (this.plannedLessons < 10 && this.plannedLessons > 0) {
      return `0${this.plannedLessons}`;
    }
    return 0;
  }

  public get formattedPlannedLessonsMinutes() {
    if (this.plannedLessonsMinutes && this.plannedLessonsMinutes > 9) {
      return `${this.plannedLessonsMinutes}`;
    } else if (this.plannedLessonsMinutes < 10 && this.plannedLessonsMinutes > 0) {
      return `0${this.plannedLessonsMinutes}`;
    }
    return "00";
  }

  public get formattedExternalServices() {
    if (this.externalServices && this.externalServices > 9) {
      return `${this.externalServices}`;
    } else if (this.externalServices < 10 && this.externalServices > 0) {
      return `0${this.externalServices}`;
    }
    return 0;
  }

  public get formattedExternalServicesMinutes() {
    if (this.externalServicesMinutes && this.externalServicesMinutes > 9) {
      return `${this.externalServicesMinutes}`;
    } else if (this.externalServicesMinutes < 10 && this.externalServicesMinutes > 0) {
      return `0${this.externalServicesMinutes}`;
    }
    return "00";
  }
}
