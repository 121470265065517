











import { Component, Vue } from "vue-property-decorator";
import UploadDocument from "@/components/UploadDocument.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";

const DocumentModule = namespace("document");

@Component({
  components: { UploadDocument },
})
export default class StudentUploadDocument extends mixins(TabMixin) {
  public name = "StudentUploadDocument";

  @DocumentModule.Action("uploadDocument")
  public uploadDocument!: (options: ICrudOptions) => Promise<void>;

  @DocumentModule.Getter("getIsLoading")
  public isLoading!: boolean;

  @DocumentModule.Getter("getSuccess")
  public success!: boolean;

  public componentVisible = false;
  private fileSizeInvalid = false;

  public get visible() {
    return this.componentVisible;
  }

  public mounted() {
    this.componentVisible = true;
  }

  public async onSelectFile(files: Array<File>): Promise<void> {
    let formData = new FormData();
    this.fileSizeInvalid = !checkFileSizeBeforeUpload(files[0], UploadConfiguration.SIZE_MULTI_PAGES);
    if (this.fileSizeInvalid) return;

    formData.append("file", files[0]);
    await this.uploadDocument({ resource: "documents/upload-document-for-processing", data: formData });

    if (this.success) {
      await this.$router.push({
        name: "StudentUploadDocuments",
        params: {
          fetch: String(true),
        },
      });
    }
  }

  public abort() {
    this.componentVisible = false;
    this.fileSizeInvalid = false;
    this.closeCurrentTab();
  }
}
