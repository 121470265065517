
import { Vue, Component, Prop } from "vue-property-decorator";
import CustomSlider from "@/components/Slider/CustomSlider.vue";
import { PropType } from "vue";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import { IAbibaroStatus } from "@/interfaces/Student/IAbibaroStatus";
import { IStudent } from "@/interfaces/Student/IStudent";

@Component({
  components: { CustomSlider },
})
export default class StudentDriveBuzzAbibaroStatusCard extends Vue {
  public name = "StudentDriveBuzzAbibaroStatusCard";

  @Prop({ type: Array as PropType<Array<IStudentEducation>> })
  public studentEducations!: Array<IStudentEducation>;

  @Prop({ type: Object as PropType<IAbibaroStatus> })
  public studentEducationAbibaroStatus!: IAbibaroStatus;

  @Prop({ type: Object as PropType<IStudent> })
  public student!: IStudent;

  @Prop({ type: Number })
  public learnStatus!: number;

  @Prop({ type: Number })
  public activeLearnStatusIndex!: number;

  @Prop({ type: Boolean })
  public registeredInAbibaro!: boolean;

  @Prop({ type: Boolean })
  public isLoading!: boolean;

  private get studentId() {
    return this.student?.id;
  }

  private onLearnStatusRedirect(): void {
    if (this.studentId) {
      this.$router.push({
        name: "LearnStatus",
        params: { id: String(this.studentId) },
      });
    }
  }

  private onAbibaroRedirect(): void {
    if (this.studentId) {
      this.$router.push({
        name: "StudentAbibaroCreate",
        params: { id: String(this.studentId) },
      });
    }
  }

  private get learnStatusIndexProxy() {
    return this.activeLearnStatusIndex;
  }
  private set learnStatusIndexProxy(value: number) {
    this.$emit("update:activeLearnStatusIndex", value);
  }

  private get studentEducationIsRegisteredInAbibaro() {
    return this.studentEducationAbibaroStatus?.registeredInAbibaro;
  }
  private get studentEducationAbibaroProgressPercentages() {
    return this.studentEducationAbibaroStatus?.progressPercentages || 0;
  }
}
