
























import { Component, Prop, Vue } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: { SaveButton, AbortButton },
})
export default class SignTrainingContractModal extends Vue {
  public name = "SignTrainingContractModal";

  @Prop({ type: String, required: true })
  public modalId!: string;
  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  @Prop({ default: () => false })
  public fileSizeInvalid: any;
}
