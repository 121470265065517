import { Component, Vue } from "vue-property-decorator";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";

@Component
export default class EducationContractRequestMixin extends Vue {
  public createEducationContractLoading = false;
  public createEducationContractSuccess = false;
  public createEducationContractError = null;

  public async createEducationContract(id: number, date: string) {
    this.createEducationContractLoading = true;
    this.createEducationContractSuccess = false;
    this.createEducationContractError = null;

    return await axios({ url: `training-contract/create-standard?studentEducationId=${id}&issueDate=${date}`, method: "post" })
      .then(() => {
        this.createEducationContractSuccess = true;
        this.$toasted.success(this.$t("messages.save_success").toString());
      })
      .catch((error: AxiosError) => {
        this.createEducationContractError = error.response?.data;
      })
      .finally(() => {
        this.createEducationContractLoading = false;
      });
  }
}
