




import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class SignatureButton extends mixins(ButtonMixin) {
  public name = "SignatureButton";

  @Prop({ default: "", required: false })
  public text!: any;
}
