



















import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import PdfView from "@/views/AutomaticPaymentsOverview/PdfView.vue";
import MailButton from "@/components/Button/MailButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: { PrintButton, MailButton, PdfView, FscSimpleCard },
})
export default class MandatePreviewDocument extends Vue {
  public name = "MandatePreviewDocument";

  @Prop()
  public file!: any;

  @Prop()
  public mandate!: ILivePayMandate | null;

  @Inject("mandateSetVisible")
  public mandateSetVisible!: (value: boolean) => void;

  protected closePreview() {
    this.mandateSetVisible(false);
  }

  public get title(): string {
    if (this.mandate) {
      const { date, licenseClass, firstName, lastName } = this.mandate;
      const formatDate = this.$options.filters?.formatDate(date);
      return `${this.$t("general.mandate").toString()} | ${formatDate} | ${licenseClass} | ${formatStudentName(firstName, lastName)}`;
    }
    return "";
  }
}
