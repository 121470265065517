













































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SaldoMixin from "@/mixins/SaldoMixin";
import { PropType } from "vue";
import { ISaldo } from "@/interfaces/Student/ISaldo";

@Component({
  components: {},
})
export default class StudentBalance extends mixins(SaldoMixin) {
  public name = "StudentBalance";

  @Prop({ type: Array as PropType<Array<ISaldo>>, default: () => [] })
  public saldo!: Array<ISaldo>;

  @Prop()
  public file!: any;

  @Prop()
  public coins!: any;

  @Prop()
  public scale!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  private selectedSaldo: ISaldo | null = null;

  public onVorschauSwitch: any = "";

  private get actualToPay() {
    return this.selectedSaldo?.actualToPay;
  }

  private get actualPaid() {
    return this.selectedSaldo?.actualPaid;
  }

  private get actualBalance() {
    return this.selectedSaldo?.actualBalance;
  }

  private get expectedPayments() {
    return this.selectedSaldo?.expectedPayments;
  }

  private get previewBalance() {
    return this.selectedSaldo?.previewBalance;
  }

  public defineColor(data: any) {
    if (data < 0) {
      return "bg-danger";
    }
    return "bg-success";
  }

  public get saldoOptions(): Array<any> {
    if (!this.saldo || !Array.isArray(this.saldo)) {
      return [];
    }
    return this.saldo.map((opt: any) => {
      return {
        ...opt,
        licenseClass: opt.licenseClass !== null ? opt.licenseClass : this.$t("todo.all"),
      };
    });
  }

  @Watch("saldo", { immediate: true, deep: true })
  private onSaldoChanged(saldo: Array<ISaldo>): void {
    if (this.saldo.length > 0) {
      this.selectedSaldo = saldo[0];
    }
  }
}
