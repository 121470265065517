import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import { IStudent } from "@/interfaces/IStudent";
import createCancelableRequest from "@/utils/axiosCancelable";
import axios from "@/utils/axios";
import { IExamAndEducation } from "@/interfaces/Exam/IExamAndEducation";
import moment from "moment";

const URL = "/students";

@Component
export default class StudentRequestMixin extends Vue {
  private studentCancelableRequest = createCancelableRequest(StudentRequestMixin.name);
  private examAndEducationCancelableRequest = createCancelableRequest(StudentRequestMixin.name);

  protected student: IStudent | any | null = null;
  protected studentSuccess = false;
  protected studentError: Record<any, any> | null = null;
  private studentLoadingQueue = 0;
  protected get studentLoading() {
    return this.studentLoadingQueue > 0;
  }

  public async fetchStudent(studentId: number): Promise<void> {
    this.studentSuccess = false;
    this.studentError = null;
    this.studentLoadingQueue++;
    return await this.studentCancelableRequest
      .makeRequest({ method: "get", url: `${URL}/${studentId}` })
      .then((response: AxiosResponse) => {
        this.student = response.data;
      })
      .catch((error: AxiosError) => {
        this.studentError = error.response?.data;
      })
      .finally(() => {
        this.studentLoadingQueue--;
      });
  }

  protected examAndEducationByStudent: Array<IExamAndEducation> = [];
  protected examAndEducationByStudentSuccess = false;
  protected examAndEducationByStudentError: Record<any, any> | null = null;
  private examAndEducationByStudentLoadingQueue = 0;
  protected get examAndEducationByStudentLoading() {
    return this.examAndEducationByStudentLoadingQueue > 0;
  }
  public async findExamAndEducationByStudent(studentId: number, includeArchived: boolean): Promise<void> {
    this.examAndEducationByStudentLoadingQueue++;
    this.examAndEducationByStudentSuccess = false;
    this.examAndEducationByStudentError = null;
    return await this.examAndEducationCancelableRequest
      .makeRequest({ url: `${URL}/exam-and-education/${studentId}?includeArchived=${includeArchived}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.examAndEducationByStudent = response.data;
        this.examAndEducationByStudentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.examAndEducationByStudentError = error.response?.data;
      })
      .finally(() => {
        this.examAndEducationByStudentLoadingQueue--;
      });
  }

  protected visibleForCurrentUser: Array<any> = [];
  protected visibleForCurrentUserSuccess = false;
  protected visibleForCurrentUserError: Record<any, any> | null = null;
  protected visibleForCurrentUserLoading = false;

  public async findVisibleForCurrentUser(): Promise<void> {
    this.visibleForCurrentUserLoading = true;
    this.visibleForCurrentUserSuccess = false;
    this.visibleForCurrentUserError = null;
    return await axios
      .post(`${URL}/visible-for-current-user`)
      .then((response: AxiosResponse) => {
        this.visibleForCurrentUser = response.data;
        this.visibleForCurrentUserSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.visibleForCurrentUserError = error.response?.data;
      })
      .finally(() => {
        this.visibleForCurrentUserLoading = false;
      });
  }

  public allowDriveBuzzLoginSuccess = false;
  public allowDriveBuzzLoginError = null;
  public allowDriveBuzzLoginLoading = false;

  public async allowDriveBuzzLogin(id: number, allowed: boolean): Promise<void> {
    this.allowDriveBuzzLoginLoading = true;
    this.allowDriveBuzzLoginSuccess = false;
    this.allowDriveBuzzLoginError = null;
    return await axios
      .put(`students/${id}/change-drive-buzz-login-allowed?allowed=${allowed}`)
      .then((response: AxiosResponse) => {
        this.allowDriveBuzzLoginSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.allowDriveBuzzLoginError = error.response?.data;
      })
      .finally(() => {
        this.allowDriveBuzzLoginLoading = false;
      });
  }

  public archiveStudentSuccess = false;
  public archiveStudentError = null;
  public archiveStudentLoading = false;

  public async archiveStudent(id: number, archived: boolean): Promise<void> {
    this.archiveStudentLoading = true;
    this.archiveStudentSuccess = false;
    this.archiveStudentError = null;
    return await axios
      .post(`${URL}/archive?id=${id}&archived=${archived}`)
      .then((response: AxiosResponse) => {
        this.archiveStudentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.archiveStudentError = error.response?.data;
      })
      .finally(() => {
        this.archiveStudentLoading = false;
      });
  }

  public destroyed() {
    this.examAndEducationCancelableRequest.cancelPreviousRequest();
  }
}
