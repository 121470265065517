import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";
import createCancelableRequest from "@/utils/axiosCancelable";
import axios from "@/utils/axios";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";

const crudService = new CrudService();
const URL = "/student-educations";

@Component
export default class StudentEducationRequestMixin extends Vue {
  protected studentEducations: Array<IStudentEducation> = [];
  protected studentEducationsSuccess = false;
  protected studentEducationsError: Record<any, any> | null = null;
  protected studentEducationsLoadingQueue = 0;
  public get studentEducationsLoading() {
    return this.studentEducationsLoadingQueue > 0;
  }

  private studentEducationCancelableRequest = createCancelableRequest(StudentEducationRequestMixin.name);

  public async fetchStudentEducations(studentId: number): Promise<void> {
    this.studentEducationsLoadingQueue++;
    this.studentEducationsSuccess = false;
    this.studentEducationsError = null;
    return await crudService
      .findAll({
        resource: `${URL}/student/${studentId}`,
      })
      .then((response: AxiosResponse) => {
        this.studentEducations = response.data;
      })
      .catch((error: AxiosError) => {
        this.studentEducationsError = error.response?.data;
      })
      .finally(() => {
        this.studentEducationsLoadingQueue--;
      });
  }

  public async fetchStudentEducationsArchived(studentId: number, includeArchived: boolean): Promise<void> {
    this.studentEducationsLoadingQueue++;
    this.studentEducationsSuccess = false;
    this.studentEducationsError = null;
    return await this.studentEducationCancelableRequest
      .makeRequest({
        url: `${URL}/student/${studentId}`,
        params: { includeArchived: includeArchived },
      })
      .then((response: AxiosResponse) => {
        this.studentEducations = response.data;
        this.studentEducationsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.studentEducationsError = error.response?.data;
      })
      .finally(() => {
        this.studentEducationsLoadingQueue--;
      });
  }

  protected studentEducation: IStudentEducation | null = null;
  protected studentEducationSuccess = false;
  protected studentEducationError: Record<any, any> | null = null;
  protected studentEducationLoading = false;

  protected async fetchStudentEducation(studentId: number): Promise<void> {
    this.studentEducationLoading = true;
    this.studentEducationSuccess = false;
    this.studentEducationError = null;
    return await crudService
      .findOne({
        resource: `${URL}/student`,
        id: studentId,
      })
      .then((response: AxiosResponse) => {
        this.studentEducation = response.data;
      })
      .catch((error: AxiosError) => {
        this.studentEducationError = error.response?.data;
      })
      .finally(() => {
        this.studentEducationLoading = false;
      });
  }

  public studentEducationsWithGrantingTypeAndKeyNumbersLoading = false;
  public studentEducationsWithGrantingTypeAndKeyNumbersSuccess = false;
  public studentEducationsWithGrantingTypeAndKeyNumbersError = null;
  public studentEducationsWithGrantingTypeAndKeyNumbers: any = [];

  public async fetchStudentEducationsWithGrantingTypeAndKeyNumbers(studentId: number): Promise<any> {
    this.studentEducationsWithGrantingTypeAndKeyNumbersLoading = true;
    this.studentEducationsWithGrantingTypeAndKeyNumbersSuccess = false;
    this.studentEducationsWithGrantingTypeAndKeyNumbersError = null;

    return new Promise((resolve, reject) => {
      crudService
        .findAll({
          resource: `${URL}/student/${studentId}`,
        })
        .then((response: AxiosResponse) => {
          const gratingTypes = [];
          const keyNumbers = [];
          const educations = [];

          for (const education of response.data) {
            const { keyNumber, licenseClass } = education.licenseClassWithKeyNumber;

            const buildLicenseClass = keyNumber ? `${licenseClass}${keyNumber}` : licenseClass;

            const prom = crudService
              .findAll({
                resource: `/granting-types/by-license-class?licenseClass=${buildLicenseClass}`,
                descriptionField: "",
              })
              .then((res: AxiosResponse) => {
                return res.data;
              });
            const prom2 = crudService
              .findAll({
                resource: `/license-classes/key-numbers?baseLicenseClass=${buildLicenseClass}`,
                descriptionField: "",
              })
              .then((res: AxiosResponse) => {
                return res.data;
              });

            educations.push(education);
            gratingTypes.push(prom);
            keyNumbers.push(prom2);
          }

          Promise.all([educations, gratingTypes, keyNumbers].map(Promise.all, Promise)).then((res) => {
            resolve(res);
          });
        });
    })
      .then(([educations, grantingTypes, keyNumbers]: any) => {
        const internalArr = [];
        for (const index of educations.keys()) {
          internalArr.push({
            education: educations[index],
            grantingTypes: grantingTypes[index],
            keyNumbers: keyNumbers[index],
          });
        }
        this.studentEducationsWithGrantingTypeAndKeyNumbers = internalArr;
      })
      .catch((err) => {
        this.studentEducationsWithGrantingTypeAndKeyNumbersError = err;
      })
      .finally(() => {
        this.studentEducationsWithGrantingTypeAndKeyNumbersLoading = false;
      });
  }

  public delStudentEducationSuccess = false;
  public delStudentEducationLoading = false;
  public delStudentEducationError = null;

  public async delStudentEducation(id: number) {
    this.delStudentEducationSuccess = false;
    this.delStudentEducationLoading = true;
    this.delStudentEducationError = null;
    return await axios
      .delete(`/student-educations/${id}`)
      .then((response: AxiosResponse) => {
        this.delStudentEducationSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.delStudentEducationError = error.response?.data;
      })
      .finally(() => {
        this.delStudentEducationLoading = false;
      });
  }

  public studentEducationArchiveSuccess = false;
  public studentEducationArchiveLoading = false;
  public studentEducationArchiveError = null;

  public async studentEducationArchive(educationId: number) {
    this.studentEducationArchiveSuccess = false;
    this.studentEducationArchiveLoading = true;
    this.studentEducationArchiveError = null;
    return await axios
      .put(`/student-educations/archive/${educationId}`)
      .then((response: AxiosResponse) => {
        this.studentEducationArchiveSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.studentEducationArchiveError = error.response?.data;
      })
      .finally(() => {
        this.studentEducationArchiveLoading = false;
      });
  }

  public destroyed() {
    this.studentEducationCancelableRequest.cancelPreviousRequest();
  }
}
