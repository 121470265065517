import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import i18n from "@/i18n";

export const CLASSIC_PAY = 1;
export const LIVE_PAY = 2;
export const FLASH_PAY = 3;

export const PAYMENT_WORKFLOWS: Array<IBasicNamedDTO> = [
  {
    id: CLASSIC_PAY,
    name: i18n.t("automatic_payment.classic_pay").toString(),
  },
  {
    id: LIVE_PAY,
    name: i18n.t("configuration.live_pay").toString(),
  },
  // {
  //   id: FLASH_PAY,
  //   name: i18n.t("automatic_payment.flash_pay").toString(),
  // },
];
