export const INITIAL_GRANTING = 1;
export const EXTENSION = 2;
export const ASCENSION = 3;
export const TRANSFER = 4;
export const CERTIFICATE = 7;
export const ELIMINATION_OF_AUTOMATIC_RESTRICTION = 10;

export const GRANTING_TYPES = [
  {
    id: INITIAL_GRANTING,
    name: "Ersterteilung",
  },
  {
    id: EXTENSION,
    name: "Erweiterung",
  },
  {
    id: ASCENSION,
    name: "Umschreibung",
  },
  {
    id: TRANSFER,
    name: "Sonstige Ausbildung",
  },
  {
    id: CERTIFICATE,
    name: "Certificate",
  },
  {
    id: ELIMINATION_OF_AUTOMATIC_RESTRICTION,
    name: "Aufhebung Automatikeintrag",
  },
];
