import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IPayment } from "@/interfaces/Student/IPayment";

const URL = "payments";

@Component
export default class PaymentRequestMixin extends Vue {
  public createdPaymentId = 0;
  public createPaymentLoading = false;
  public createPaymentSuccess = false;
  public createPaymentError: Record<any, any> | null = null;

  public async createPayment(payment: IPayment): Promise<void> {
    this.createPaymentLoading = true;
    this.createPaymentSuccess = false;
    this.createPaymentError = null;

    return await axios
      .post(`${URL}`, payment)
      .then((response: AxiosResponse) => {
        this.createdPaymentId = response.data;
        this.createPaymentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createPaymentError = error.response?.data;
      })
      .finally(() => {
        this.createPaymentLoading = false;
      });
  }
}
