import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import createCancelableRequest from "@/utils/axiosCancelable";
import { IChecklist } from "@/interfaces/Student/IChecklist";

@Component
export default class CheckListRequestMixin extends Vue {
  private checklistCancelableRequest = createCancelableRequest(CheckListRequestMixin.name);
  private checklistUpdateCancelableRequest = createCancelableRequest(CheckListRequestMixin.name);

  public checkListByStudentByStudent: Array<IChecklist> = [];
  public checkListByStudentLoadingQueue = 0;
  public checkListByStudentSuccess = false;
  public checkListByStudentError: Record<any, any> | null = null;

  public get checkListByStudentLoading() {
    return this.checkListByStudentLoadingQueue > 0;
  }

  public async findCheckListsByStudent(studentId: number): Promise<void> {
    this.checkListByStudentLoadingQueue++;
    this.checkListByStudentSuccess = false;
    this.checkListByStudentError = null;
    return await this.checklistCancelableRequest
      .makeRequest({ url: `/checklist/by-student/${studentId}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.checkListByStudentByStudent = response.data;
        this.checkListByStudentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.checkListByStudentError = error.response?.data;
      })
      .finally(() => {
        this.checkListByStudentLoadingQueue--;
      });
  }

  public updatedCheckList: Record<any, any> | null = null;
  private updateCheckListLoadingQueue = 0;
  public updateCheckListSuccess = false;
  public updateCheckListError: Record<any, any> | null = null;
  public get updateCheckListLoading() {
    return this.updateCheckListLoadingQueue > 0;
  }

  public async updateCheckLists(checkListId: number, checked: boolean): Promise<void> {
    this.updateCheckListLoadingQueue++;
    this.updateCheckListSuccess = false;
    this.updateCheckListError = null;
    return await this.checklistUpdateCancelableRequest
      .makeRequest({ url: `/checklist/check/${checkListId}/?checked=${checked}`, method: "put" })
      .then((response: AxiosResponse) => {
        this.updatedCheckList = response.data;
        this.updateCheckListSuccess = true;
        this.$toasted.success(String(this.$t("messages.save_success")));
      })
      .catch((error: AxiosError) => {
        this.updateCheckListError = error.response?.data;
      })
      .finally(() => {
        this.updateCheckListLoadingQueue--;
      });
  }

  public createdCheckList: Record<any, any> | null = null;
  public createCheckListLoading = false;
  public createCheckListSuccess = false;
  public createCheckListError: Record<any, any> | null = null;

  public async createCheckListByStudent(studentId: number, checklistTemplateId: number): Promise<void> {
    this.createCheckListLoading = true;
    this.createCheckListSuccess = false;
    this.createCheckListError = null;
    return await axios
      .post(`/checklist/template/${studentId}/${checklistTemplateId}`)
      .then((response: AxiosResponse) => {
        this.createdCheckList = response.data;
        this.createCheckListSuccess = true;
        this.$toasted.success(String(this.$t("messages.save_success")));
      })
      .catch((error: AxiosError) => {
        this.createCheckListError = error.response?.data;
      })
      .finally(() => {
        this.createCheckListLoading = false;
      });
  }

  public removedCheckListTemplate: Record<any, any> | null = null;
  public removeCheckListTemplateLoading = false;
  public removeCheckListTemplateSuccess = false;
  public removeCheckListTemplateError: Record<any, any> | null = null;

  public async removeCheckListTemplateByStudent(studentId: number, checklistTemplateId: number): Promise<void> {
    this.removeCheckListTemplateLoading = true;
    this.removeCheckListTemplateSuccess = false;
    this.removeCheckListTemplateError = null;
    return await axios
      .delete(`/checklist/template/${studentId}/${checklistTemplateId}`)
      .then((response: AxiosResponse) => {
        this.removedCheckListTemplate = response.data;
        this.removeCheckListTemplateSuccess = true;
        this.$toasted.success(String(this.$t("messages.delete_success")));
      })
      .catch((error: AxiosError) => {
        this.removeCheckListTemplateError = error.response?.data;
      })
      .finally(() => {
        this.removeCheckListTemplateLoading = false;
      });
  }

  private checklistByCostBearerCancelableRequest = createCancelableRequest(CheckListRequestMixin.name);

  public checklistByCostBearer: Array<any> = [];
  public checklistByCostBearerSuccess = false;
  public checklistByCostBearerError: Record<any, any> | null = null;
  private checklistByCostBearerQueue = 0;

  public get checklistByCostBearerLoading() {
    return this.checklistByCostBearerQueue > 0;
  }

  public async checklistByCostBearerAction(costBearerId: number): Promise<void> {
    this.checklistByCostBearerQueue++;
    this.checklistByCostBearerSuccess = false;
    this.checklistByCostBearerError = null;
    return await this.checklistByCostBearerCancelableRequest
      .makeRequest({
        url: `/checklist/by-cost-bearer/${costBearerId}`,
        method: "get",
      })
      .then((response: AxiosResponse) => {
        this.checklistByCostBearer = response.data;
        this.checklistByCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.checklistByCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.checklistByCostBearerQueue--;
      });
  }

  // create checklist template by cost bearer
  private createChecklistTemplateByCostBearerCancelableRequest = createCancelableRequest(CheckListRequestMixin.name);

  public createChecklistTemplateByCostBearerSuccess = false;
  public createChecklistTemplateByCostBearerError: Record<any, any> | null = null;
  private createChecklistTemplateByCostBearerQueue = 0;

  public get createChecklistTemplateByCostBearerLoading() {
    return this.createChecklistTemplateByCostBearerQueue > 0;
  }

  public async createChecklistTemplateByCostBearerAction(costBearerId: number, checklistTemplateId: number): Promise<void> {
    this.createChecklistTemplateByCostBearerQueue++;
    this.createChecklistTemplateByCostBearerSuccess = false;
    this.createChecklistTemplateByCostBearerError = null;
    return await this.createChecklistTemplateByCostBearerCancelableRequest
      .makeRequest({
        url: `/checklist/template/cost-bearer/${costBearerId}/${checklistTemplateId}`,
        method: "post",
      })
      .then((response: AxiosResponse) => {
        this.createChecklistTemplateByCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createChecklistTemplateByCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.createChecklistTemplateByCostBearerQueue--;
      });
  }

  //  check checklist by cost bearer
  private checkChecklistByCostBearerCancelableRequest = createCancelableRequest(CheckListRequestMixin.name);

  public checkChecklistByCostBearerSuccess = false;
  public checkChecklistByCostBearerError: Record<any, any> | null = null;
  private checkChecklistByCostBearerQueue = 0;

  public get checkChecklistByCostBearerLoading() {
    return this.checkChecklistByCostBearerQueue > 0;
  }

  public async checkChecklistByCostBearerAction(checklistItemId: number, checked: boolean): Promise<void> {
    this.checkChecklistByCostBearerQueue++;
    this.checkChecklistByCostBearerSuccess = false;
    this.checkChecklistByCostBearerError = null;
    return await this.checkChecklistByCostBearerCancelableRequest
      .makeRequest({
        url: `/checklist/cost-bearer/check/${checklistItemId}?checked=${checked}`,
        method: "put",
      })
      .then((response: AxiosResponse) => {
        this.checkChecklistByCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.checkChecklistByCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.checkChecklistByCostBearerQueue--;
      });
  }

  //  delete checklist template by cost bearer
  private deleteChecklistTemplateByCostBearerCancelableRequest = createCancelableRequest(CheckListRequestMixin.name);

  public deleteChecklistTemplateByCostBearerSuccess = false;
  public deleteChecklistTemplateByCostBearerError: Record<any, any> | null = null;
  private deleteChecklistTemplateByCostBearerQueue = 0;

  public get deleteChecklistTemplateByCostBearerLoading() {
    return this.deleteChecklistTemplateByCostBearerQueue > 0;
  }

  public async deleteChecklistTemplateByCostBearerAction(costBearerId: number, checklistTemplateId: number): Promise<void> {
    this.deleteChecklistTemplateByCostBearerQueue++;
    this.deleteChecklistTemplateByCostBearerSuccess = false;
    this.deleteChecklistTemplateByCostBearerError = null;
    return await this.deleteChecklistTemplateByCostBearerCancelableRequest
      .makeRequest({
        url: `/checklist/template/cost-bearer/${costBearerId}/${checklistTemplateId}`,
        method: "delete",
      })
      .then((response: AxiosResponse) => {
        this.deleteChecklistTemplateByCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.deleteChecklistTemplateByCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.deleteChecklistTemplateByCostBearerQueue--;
      });
  }

  public destroyed() {
    this.checklistCancelableRequest.cancelPreviousRequest();
    this.checklistUpdateCancelableRequest.cancelPreviousRequest();
    this.checklistByCostBearerCancelableRequest.cancelPreviousRequest();
    this.createChecklistTemplateByCostBearerCancelableRequest.cancelPreviousRequest();
    this.checkChecklistByCostBearerCancelableRequest.cancelPreviousRequest();
    this.deleteChecklistTemplateByCostBearerCancelableRequest.cancelPreviousRequest();
  }
}
