import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import { ulrStringify } from "@/utils/UrlUtils";
import createCancelableRequest from "@/utils/axiosCancelable";

const URL = "vat-options";

@Component
export default class VatRequestMixin extends Vue {
  private vatCancelableRequest = createCancelableRequest(VatRequestMixin.name);

  public vatOptions: Array<any> = [];
  public vatOptionsLoadingQueue = 0;
  public vatOptionsSuccess = false;
  public vatOptionsError: Record<any, any> | null = null;

  public get vatOptionsLoading() {
    return this.vatOptionsLoadingQueue > 0;
  }

  public async findAllVats(params: any = {}): Promise<void> {
    this.vatOptionsLoadingQueue++;
    this.vatOptionsSuccess = false;
    this.vatOptionsError = null;

    const args = ulrStringify(params);

    return await this.vatCancelableRequest
      .makeRequest({ url: `${URL}${args}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.vatOptions = response.data;
        this.vatOptionsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.vatOptionsError = error.response?.data;
      })
      .finally(() => {
        this.vatOptionsLoadingQueue--;
      });
  }

  public destroyed() {
    this.vatCancelableRequest.cancelPreviousRequest();
  }
}
