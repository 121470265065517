











































































































import PrintButton from "@/components/Button/PrintButton.vue";
import { Component, Vue, Prop, InjectReactive } from "vue-property-decorator";
import FileSignatureButton from "@/components/Button/FileSignatureButton.vue";
import SignTrainingContract from "@/views/Student/Info/SignTrainingContract.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import TrainingContract from "@/views/Student/Info/TrainingContract.vue";
import EditButton from "@/components/Button/EditButton.vue";
import { IExamAndEducation } from "@/interfaces/Exam/IExamAndEducation";
import { PropType } from "vue";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import { IStudent } from "@/interfaces/Student/IStudent";

@Component({
  components: {
    TrainingContract,
    SignTrainingContract,
    FileSignatureButton,
    PrintButton,
    FscHorizontalTable,
    EditButton,
  },
})
export default class StudentEducation extends Vue {
  public name = "StudentEducation";

  protected columns = [
    {
      label: this.$t("general.test_organization"),
      key: "testingOrganization",
    },
    {
      label: this.$t("general.price_list"),
      key: "initialPriceList",
    },
    {
      label: this.$t("general.fast_course"),
      key: "theoryCourse",
    },
    {
      label: this.$tc("calendar.other", 2),
      key: "info",
    },
  ];

  @Prop({ type: Array as PropType<Array<IExamAndEducation>>, default: () => [], required: true })
  public examAndEducationList!: Array<IExamAndEducation>;

  @Prop({ type: Object as PropType<IStudent>, required: false })
  public student?: IStudent;

  @Prop({ type: Array as PropType<Array<IStudentEducation>>, default: () => [], required: true })
  public studentEducations!: Array<IStudentEducation>;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public activeExam = 0;
  public activeTestOrg = 0;

  public editDisabled = false;

  public tabClass = `fsc`;
  public opacityClass = `m-0 pt-2`;
  public testTabClass = `fsc`;
  public testOpacityClass = `m-0 pt-2`;

  @InjectReactive("isDisabled")
  public disabled!: boolean;

  private get selectedExamAndEducation() {
    return this.examAndEducationList?.[this.activeExam];
  }
  private get selectedStudentEducation() {
    return this.studentEducations?.[this.activeExam];
  }

  private get theoryExamDate() {
    return this.selectedExamAndEducation?.theoryExamDate;
  }
  private get theoryExamCount() {
    return this.selectedExamAndEducation?.theoryExamCount;
  }

  private get practicalExamDate() {
    return this.selectedExamAndEducation?.practicalExamDate;
  }
  private get practicalExamCount() {
    return this.selectedExamAndEducation?.practicalExamCount;
  }

  private get selectedStudentEducationTestOrg() {
    return this.studentEducations?.[this.activeTestOrg];
  }

  public onArchiveEducation(selectedEducation: any) {
    this.$emit("archive-education", selectedEducation);
  }

  public onEducationFinishedDatesEdit() {
    if (this.student?.id) {
      this.$router.push({
        name: "StudentEdit",
        params: { id: String(this.student.id) },
      });
    }
  }

  private get theoryExamPassed() {
    return this.selectedExamAndEducation?.theoryExamPassed;
  }

  private get practicalExamPassed() {
    return this.selectedExamAndEducation?.practicalExamPassed;
  }
}
