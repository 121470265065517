




//@ts-ignore
import VueMonthlyPicker from "vue-monthly-picker";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    VueMonthlyPicker,
  },
})
export default class Monthpicker extends Vue {
  public name = "Monthpicker";

  public monthLabels: any = [
    this.$t("months.jan"),
    this.$t("months.feb"),
    this.$t("months.mar"),
    this.$t("months.apr"),
    this.$t("months.may"),
    this.$t("months.jun"),
    this.$t("months.jul"),
    this.$t("months.aug"),
    this.$t("months.sep"),
    this.$t("months.oct"),
    this.$t("months.nov"),
    this.$t("months.dec"),
  ];

  @Prop()
  public value!: any;

  public selectedMonth(value: any): void {
    const data = value.format("YYYY-MM-DD");
    this.$emit("input", data);
  }
}
