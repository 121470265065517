












import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: { SaveButton, FscModal },
})
export default class DeleteEducationSecondModal extends Vue {
  public name = "DeleteEducationSecondModal";

  @Prop({ type: String })
  public modalId!: string;

  @Prop({ type: String })
  public studentName!: string;

  @Prop({ type: String })
  public studentEducationLicenseClass!: string;

  @Prop({ type: Function })
  public okFn!: () => Promise<boolean>;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public async okHandle(cb: () => void) {
    const success = await this.okFn();
    if (success) {
      cb();
      this.$root.$emit("refresh-student-info");
    }
  }
}
